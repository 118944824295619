a {
    color: #ffffff;
    text-decoration: none;
    z-index: 10;
}

video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
}

.hiThere {
    padding: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
}
